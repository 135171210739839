import { IonApp, setupIonicReact } from "@ionic/react";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/display.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/padding.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";

/* Theme variables */
import "./theme/variables.css";

/** Mapbox */
import "mapbox-gl/dist/mapbox-gl.css";

import Routes from "./Routes";
import StyleProvider from "./components/provider/StyleProvider";
import Modal from "./components/Modal";

setupIonicReact({
  rippleEffect: false,
  mode: "ios",
});

const App: React.FC = () => {
  return (
    <StyleProvider>
      <IonApp>
        <Modal>
          <Routes />
        </Modal>
      </IonApp>
    </StyleProvider>
  );
};

export default App;
