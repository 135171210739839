import { supabase } from "../externals/supabase";
import useSWR from "swr";

export const getDevices = async () => {
  return await supabase.from("devices").select();
};

export const useDevices = () => {
  const { data, error, ...swr } = useSWR("devices", getDevices);
  const isLoading = !error && !data;

  return { data, isLoading, error, ...swr };
};
