import * as React from "react";
import { PropsWithChildren } from "react";
import useSelectedDevice from "../../global-states/devices";

export type Notification = {
  type: "test" | "engine" | "stop";
  title: string;
  content: string;
};

export const NotificationComp = () => {
  return <></>;
};

const NotificationProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { device } = useSelectedDevice();

  if (!device?.user_id || !device.device_id) return <>{children}</>;

  return <>{children}</>;
};

export default NotificationProvider;
