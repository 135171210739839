import { IonContent, IonPage, useIonRouter } from "@ionic/react";
import { useStyletron } from "baseui";
import { Button } from "baseui/button";
import { Card, StyledAction, StyledBody } from "baseui/card";
import { ListItem, ListItemLabel } from "baseui/list";
import { HeadingMedium, ParagraphSmall } from "baseui/typography";
import { FC, useCallback, useState } from "react";
import { RouteChildrenProps } from "react-router";
import AuthMiddleware from "../components/middleware/AuthMiddleware";
import NotPremiumMiddleware from "../components/middleware/NotPremiumMiddleware";
import MenuWrapper from "../components/wrapper/MenuWrapper";
import { supabase } from "../externals/supabase";
import { GlobalAPI } from "../lib/api";
import { withBackendUrl } from "../utils/withBackendUrl";

const PlanPage: FC<RouteChildrenProps> = () => {
  const [css] = useStyletron();
  const { push } = useIonRouter();

  const [isLoading, setIsLoading] = useState(false);

  const goToPayment = useCallback(async () => {
    setIsLoading(true);

    try {
      const user = await supabase.auth.getSession();
      const result = await GlobalAPI.request(
        withBackendUrl("/api/v1/payment/create/" + user.data.session?.user.id),
        {
          method: "post",
        }
      );
      const clientSecret = result.data.clientSecret;

      push("/payment?payment_intent_client_secret=" + clientSecret);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  }, [push]);

  return (
    <AuthMiddleware>
      <NotPremiumMiddleware>
        <IonPage>
          <IonContent>
            <MenuWrapper>
              <div
                className={`${css({
                  display: "flex",
                  flexDirection: "column",
                  alignContent: "center",
                  justifyContent: "center",
                  minHeight: "100%",
                  maxWidth: "400px",
                  margin: "0 auto",
                })} ion-padding`}
              >
                <Card>
                  <StyledBody>
                    <HeadingMedium>
                      Keisha will protect your devices 24/7 with the folowing
                      features!
                    </HeadingMedium>

                    <ul
                      className={css({
                        width: "100%",
                        maxWidth: "375px",
                        paddingLeft: 0,
                        marginLeft: "-10px",
                        paddingRight: 0,
                      })}
                    >
                      <ListItem>
                        <ListItemLabel description="Secure your devices">
                          Cut Off Engine
                        </ListItemLabel>
                      </ListItem>
                      <ListItem>
                        <ListItemLabel description="Estimate your gas usage">
                          Gas usage estimation
                        </ListItemLabel>
                      </ListItem>
                      <ListItem>
                        <ListItemLabel description="Track your any devices in real-time">
                          GPS Location
                        </ListItemLabel>
                      </ListItem>
                      <ListItem>
                        <ListItemLabel description="View your devices location history">
                          Location History
                        </ListItemLabel>
                      </ListItem>
                      <ListItem>
                        <ListItemLabel description="All is yours when they comes up">
                          And even more (upcoming)
                        </ListItemLabel>
                      </ListItem>
                    </ul>
                  </StyledBody>
                  <StyledAction>
                    <Button
                      isLoading={isLoading}
                      onClick={goToPayment}
                      overrides={{
                        BaseButton: {
                          style: {
                            width: "100%",
                            marginTop: "10px",
                          },
                        },
                      }}
                    >
                      Buy Keisha snack for $1
                    </Button>
                    <ParagraphSmall $style={{ textAlign: "center" }}>
                      All those awesome features just $1 in a month
                    </ParagraphSmall>
                  </StyledAction>
                </Card>
              </div>
            </MenuWrapper>
          </IonContent>
        </IonPage>
      </NotPremiumMiddleware>
    </AuthMiddleware>
  );
};

export default PlanPage;
