import moment from "moment-timezone";
import { supabase } from "../externals/supabase";
import { Device } from "../global-states/devices";
import { withBackendUrl } from "../utils/withBackendUrl";

export type Error = {
  code: number;
  id: number;
  reason: string;
};

export type Result<T> = {
  begin: number;
  duration: number;
  end: number;
} & T & { [k: string]: unknown };

export type Response<R = any> = {
  errors: Error[];
  result: Result<R>[];
};

export type APIConfig = {
  token: string;
};

export class GlobalAPI {
  static async request(
    endpoint: URL | RequestInfo,
    init?: RequestInit
  ): Promise<{ data: any; message: string; status: "success" | "error" }> {
    const user = await supabase.auth.getSession();
    const token = user.data.session?.access_token;

    if (!token) return { data: null, message: "auth", status: "error" };

    const headers = new Headers({
      Authorization: "Bearer " + token,
      ...init?.headers,
    });

    return fetch(endpoint, { ...init, headers }).then((r) => r.json());
  }
}

export class API {
  private baseUrl = withBackendUrl("/api/v2");
  private config: APIConfig;
  constructor(token: string, version: number = 2) {
    if (version !== 2) {
      this.baseUrl = withBackendUrl(`/api/v${version}`);
    }

    this.config = {
      token,
    };
  }

  public async request(
    endpoint: string,
    init?: RequestInit
  ): Promise<Response> {
    const headers = new Headers(init?.headers);
    headers.append("Authorization", "Bearer " + this.config.token);

    return fetch(this.baseUrl + endpoint, {
      ...init,
      headers,
    }).then((r: any) => r.json());
  }
}

type DevicesConfig = APIConfig & { deviceId: number };

export class Devices extends API {
  private devId: number;
  private path: string = "/devices";

  constructor(config: DevicesConfig) {
    super(config.token);
    this.devId = config.deviceId;
  }

  public async getLastUpdatePosition(start = new Date(), end = new Date()) {
    start.setHours(0, 0, 0, 0);
    end.setHours(23, 59, 59, 999);

    let from = moment(start).tz("Asia/Jakarta").unix();
    let to = moment(end).tz("Asia/Jakarta").unix();

    from = from + 7 * 60 * 60;
    to = to + 7 * 60 * 60;

    return { from, to };
  }

  public async getMessages(
    params: {
      from?: Date;
      to?: Date;
      [k: string]: unknown;
    } = {}
  ): Promise<Response<{ telemetry: { "alarm.code": string } }>> {
    const { from, to } = await this.getLastUpdatePosition(
      params.from,
      params.to
    );

    const parsedQuery = encodeURIComponent(
      JSON.stringify({ ...params, from, to })
    );

    const messages = await this.request(
      `${this.path}/${this.devId}/messages?data=${parsedQuery}`
    );

    messages.result = messages.result.filter((d) => d["position.latitude"]);

    return messages;
  }

  public async sendMessages(
    params: {
      [k: string]: unknown;
    }[]
  ): Promise<Response<{ telemetry: { "alarm.code": string } }>> {
    const messages = await this.request(`${this.path}/${this.devId}/messages`, {
      method: "POST",
      body: JSON.stringify(params),
    });

    return messages;
  }

  public async sendSMS(
    params: {
      [k: string]: unknown;
    }[]
  ): Promise<Response> {
    const messages = await this.request(`${this.path}/${this.devId}/sms`, {
      method: "POST",
      body: JSON.stringify(params),
    });

    return messages;
  }

  public getTelemetry(
    telemetryName: string = "all"
  ): Promise<Response<{ telemetry: { [k: string]: any } }>> {
    return this.request(
      `${this.path}/${this.devId}/telemetry/${telemetryName}`
    );
  }

  public deleteTelemetry(telemetryName: string) {
    return this.request(
      `${this.path}/${this.devId}/telemetry/${telemetryName}`,
      { method: "DELETE" }
    );
  }

  public runCommands(params: { [k: string]: unknown }[]) {
    return this.request(`${this.path}/${this.devId}/commands`, {
      method: "POST",
      body: JSON.stringify(params),
    });
  }
}

export class Action extends Devices {
  public async relayControl(status: boolean) {
    await this.updateBlockedEngineStatus(status);
    return await this.runCommands([
      {
        name: "setting.relay.set",
        properties: { cut_off: status },
        timeout: 10,
      },
    ]);
  }

  public async relayControlSMS(
    status: boolean
  ): Promise<[isSent: Boolean, response: Response]> {
    const sentSMS = await this.sendSMS([
      {
        name: "custom",
        properties: { payload: `RELAY,${status ? "0" : "1"}#` },
      },
    ]);

    // @ts-ignore
    if (sentSMS.result[0]?.sent && !sentSMS.errors.length) {
      this.updateBlockedEngineStatus(status);
      return [true, sentSMS];
    }

    return [false, sentSMS as Response];
  }

  /**
   * Sending command via SMS didnt update status
   * so we need to update it manually.
   */
  private async updateBlockedEngineStatus(status: boolean) {
    return this.sendMessages([{ "engine.blocked.status": status }]);
  }
}

export class Backend extends API {
  // Get deviceId
  public runFirstConnect(body: Device) {
    return this.request("/devices", {
      method: "PATCH",
      body: JSON.stringify(body),
    });
  }
}
