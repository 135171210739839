import { useIonLoading } from "@ionic/react";
import { useStyletron } from "baseui";

import { Block, BlockProps } from "baseui/block";
import { Checkbox, STYLE_TYPE } from "baseui/checkbox";
import { ParagraphSmall } from "baseui/typography";
import { FC } from "react";
import useSelectedDevice from "../global-states/devices";

import useEngine from "../hooks/useEngine";

const quickActionItemsProp: BlockProps = {
  backgroundColor: "mono200",
  padding: "8px 10px",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around",
};

const ActionItemEngineComponent: FC<{ deviceId: number }> = ({ deviceId }) => {
  const [present, dismiss] = useIonLoading();
  const [css, theme] = useStyletron();
  const { handleChangeEngineStatus, isCut, IS_CUT_ON_OFF } =
    useEngine(deviceId);

  return (
    <>
      <Block
        {...quickActionItemsProp}
        backgroundColor={`${
          isCut ? theme.colors.mono200 : theme.colors.mono300
        }`}
        id="engine"
        className={css({
          borderRadius: "8px",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          border: isCut ? "" : "2px solid " + theme.colors.mono400,
        })}
      >
        <ParagraphSmall
          marginTop="12px"
          marginBottom="10px"
          className={css({
            lineHeight: "1.3 !important",
            fontWeight: "bold !important",
          })}
        >
          Engine Status
        </ParagraphSmall>

        <Checkbox
          checked={!isCut}
          checkmarkType={STYLE_TYPE.toggle_round}
          onChange={async () => {
            present("Turning the engine " + IS_CUT_ON_OFF);
            await handleChangeEngineStatus(!isCut);
            dismiss();
          }}
        ></Checkbox>
      </Block>
      {/* <IonModal
        trigger="engine"
        initialBreakpoint={0.25}
        breakpoints={[0.25]}
        ref={modal}
        backdropDismiss={true}
        animated={false}
      >
        <IonContent>
          <Block
            className={css({
              marginTop: "40px",
              marginLeft: "20px",
              marginRight: "20px",
            })}
          >
            <ParagraphLarge
              marginBottom="0"
              className={css({ fontWeight: "bold" })}
            >
              Engine setting
            </ParagraphLarge>
            <ParagraphSmall marginTop="4px" marginBottom="20px" color="mono800">
              The current status is {IS_CUT_ON_OFF_REVERSE}
            </ParagraphSmall>
            <FlexGrid flexGridColumnCount={2} flexGridColumnGap="scale400">
              <FlexGridItem>
                <Button
                  kind="primary"
                  className={css({ width: "100%" })}
                  isLoading={isLoading[0]}
                  onClick={async () => {
                    setIsLoading((is) => [true, is[1]]);
                    await handleChangeEngineStatus(false);
                    setIsLoading((is) => [false, is[1]]);
                    modal.current?.setCurrentBreakpoint(0);
                  }}
                >
                  Turn On
                </Button>
              </FlexGridItem>
              <FlexGridItem>
                <Button
                  kind="secondary"
                  className={css({ width: "100%" })}
                  isLoading={isLoading[1]}
                  onClick={async () => {
                    setIsLoading((is) => [is[0], true]);
                    await handleChangeEngineStatus(true);
                    setIsLoading((is) => [is[0], false]);
                    modal.current?.setCurrentBreakpoint(0);
                  }}
                >
                  Turn Off
                </Button>
              </FlexGridItem>
            </FlexGrid>
          </Block>
        </IonContent>
      </IonModal> */}
    </>
  );
};

const ActionItemEngine: React.FC = () => {
  const { device } = useSelectedDevice();

  if (device) return <ActionItemEngineComponent deviceId={device.device_id} />;
  return <></>;
};

export default ActionItemEngine;
