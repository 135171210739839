export const withBackendUrl = (
  path: string
): string => {
  const url =
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_BACKEND_URL
      : "http://localhost:4000";
  return url + path;
};

export const withFrontendUrl = (
  path: string
): string => {
  const url =
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_FRONTEND_URL
      : "http://localhost:3000";

  return url + path;
};
