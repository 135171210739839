import { useContext, useState } from "react";
import { AuthContext } from "../components/middleware/AuthMiddleware";

const usePremium = () => {
  const { valid_until } = useContext(AuthContext);
  const [unix] = useState(Math.floor(Date.now() / 1000));
  const isPremiumPlan =
    valid_until && new Date(valid_until).getTime() / 1000 >= unix;

  return { isPremiumPlan };
};

export default usePremium;
