import { PropsWithChildren } from "react";

/** Base Web */
import { BaseProvider, createTheme } from "baseui";
import { Client as Styletron } from "styletron-engine-atomic";
import { Provider as StyletronProvider } from "styletron-react";

const StyleProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const engine = new Styletron();
  const theme = createTheme({
    primaryFontFamily: `'Uber Move Text', system-ui, 'Helvetica Neue', Helvetica, Arial, sans-serif`,
  });

  return (
    <StyletronProvider value={engine}>
      <BaseProvider theme={theme}>{children}</BaseProvider>
    </StyletronProvider>
  );
};

export default StyleProvider;
