import { IonContent, IonPage } from "@ionic/react";
import { AppNavBar } from "baseui/app-nav-bar";
import { RouteChildrenProps } from "react-router";
import CheckoutForm from "../components/CheckoutForm";
import AuthMiddleware from "../components/middleware/AuthMiddleware";
import { StripeProvider } from "../components/provider/StripeProvider";

const Payment: React.FC<RouteChildrenProps> = () => {
  return (
    <AuthMiddleware>
      <IonPage>
        <AppNavBar title="Buy Keisha a meal" />
        <IonContent className="ion-padding">
          <StripeProvider>
            <CheckoutForm />
          </StripeProvider>
        </IonContent>
      </IonPage>
    </AuthMiddleware>
  );
};

export default Payment;
