import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { Button } from "baseui/button";
import { StyledLink } from "baseui/link";
import { HeadingSmall, ParagraphMedium } from "baseui/typography";
import { FC, useCallback } from "react";
import { supabase } from "../externals/supabase";
import useSelectedDevice from "../global-states/devices";
import { Backend } from "../lib/api";

const DevicesNotConnect: FC<{}> = () => {
  const [css] = useStyletron();
  const { device } = useSelectedDevice();

  const isLoading = false;

  const handleReconnect = useCallback(async () => {
    const token = await supabase.auth.getSession();
    if (!token.data.session?.access_token || !device) return;
    const backend = new Backend(token.data.session?.access_token, 1);
    backend.runFirstConnect(device);
  }, [device]);

  return (
    <Block
      className={css({
        marginTop: "25vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      })}
    >
      <HeadingSmall marginBottom={0}>
        {device?.name} is {!!isLoading ? "Connecting..." : "not connected"}
      </HeadingSmall>
      <ParagraphMedium className={css({ textAlign: "center" })}>
        Make sure you've been set your device server to Keisha server{" "}
        <StyledLink>(See how)</StyledLink>. Or try to re-connect by tapping the
        button below!
      </ParagraphMedium>

      <Button
        isLoading={!!isLoading}
        kind="secondary"
        onClick={handleReconnect}
      >
        Reconnect
      </Button>
    </Block>
  );
};

export default DevicesNotConnect;
