import create from "zustand";
import { combine } from "zustand/middleware";

const initialTrackHistoryDate = {
  dates: [new Date(), new Date()],
};

export const useTrackHistoryDate = create(
  combine(initialTrackHistoryDate, (set) => ({
    setDates: (dates: Date[]) => set(() => ({ dates })),
  }))
);

export const useIsModalOpen = create(
  combine({ isOpen: false }, (set) => ({
    setIsOpen: (isOpen: boolean) => set(() => ({ isOpen })),
  }))
);
