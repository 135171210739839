import { IonMenu } from "@ionic/react";
import { PropsWithChildren } from "react";
import MenuContent from "../Menu";

const MenuWrapper: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <>
      <IonMenu contentId="main">
        <MenuContent />
      </IonMenu>

      {children}
    </>
  );
};

export default MenuWrapper;
