import { Elements } from "@stripe/react-stripe-js";
import { StripeElementsOptions } from "@stripe/stripe-js";
import { PropsWithChildren, useState } from "react";
import getStripe from "../../lib/getStripe";

export const StripeProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [clientSecret] = useState<string>(
    new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    ) || ""
  );
  const options: StripeElementsOptions = {
    clientSecret,
  };

  if (!clientSecret) return <div>Please back and submit again</div>;

  return (
    <Elements stripe={getStripe()} options={options}>
      {children}
    </Elements>
  );
};
