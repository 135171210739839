import { supabase } from "../../externals/supabase";

export const getValidation = async () => {
  const session = await supabase.auth.getSession();
  const token = session?.data?.session?.access_token;

  if (!token) return { token: "auth error" };

  const id = session?.data?.session?.user.id;
  const users = await supabase.from("user_plans").select().eq("user_id", id);
  const validUntil = users?.data?.length ? users.data[0].valid_until : false;

  const result = {
    token,
    validUntil,
    user: session.data?.session?.user || null,
  };

  return result;
};
