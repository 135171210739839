import { IonContent, IonPage, useIonRouter, useIonToast } from "@ionic/react";
import { useStyletron } from "baseui";
import { Avatar } from "baseui/avatar";
import { Block } from "baseui/block";
import { Button } from "baseui/button";
import { ChevronLeft } from "baseui/icon";
import { Input } from "baseui/input";
import { ParagraphLarge, ParagraphMedium } from "baseui/typography";
import { FC, useContext, useState } from "react";
import { RouteChildrenProps } from "react-router";
import AuthMiddleware, {
  AuthContext,
} from "../components/middleware/AuthMiddleware";
import { supabase } from "../externals/supabase";

const Profile: FC = () => {
  const [css, theme] = useStyletron();
  const { user } = useContext(AuthContext);
  const [toast] = useIonToast();
  const { goBack } = useIonRouter();

  const [fullname, setFullname] = useState(user?.user_metadata?.name);

  const handleSaveFullname = async () => {
    try {
      await supabase.auth.updateUser({ data: { name: fullname } });
      toast({ message: "Full Name updated", duration: 3000 });
    } catch (error) {
      toast({ message: "Full Name failed to update", duration: 3000 });
    }
  };

  return (
    <>
      <Block
        className={css({
          display: "flex",
          gap: "10px",
          alignItems: "center",
          marginBottom: "50px",
        })}
      >
        <Button
          onClick={() => goBack()}
          size="compact"
          kind="secondary"
          className={css({
            padding: "2px !important",
            marginRight: "10px",
            display: "flex",
          })}
        >
          <ChevronLeft size="34px" />
        </Button>
        <ParagraphLarge
          className={css({ fontWeight: "bold !important" })}
          marginTop={0}
          marginBottom={0}
        >
          Profile
        </ParagraphLarge>
      </Block>
      <Block className={css({ display: "flex", flexDirection: "column" })}>
        <Block className={css({ textAlign: "center" })}>
          <Avatar
            size={theme.sizing.scale3200}
            name={user?.user_metadata?.name}
          />
        </Block>

        <br />
        <ParagraphMedium marginBottom="0"> Full Name</ParagraphMedium>
        <Input value={fullname} onChange={(e) => setFullname(e.target.value)} />
        <br />

        <Button onClick={handleSaveFullname}>Save</Button>
      </Block>
    </>
  );
};

const ProfilePage: FC<RouteChildrenProps> = () => {
  return (
    <AuthMiddleware>
      <IonPage>
        <IonContent className="ion-padding">
          <Profile />
        </IonContent>
      </IonPage>
    </AuthMiddleware>
  );
};

export default ProfilePage;
