import {
  IonContent,
  IonPage,
  useIonLoading,
  useIonRouter,
  useIonToast,
} from "@ionic/react";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { Button } from "baseui/button";
import { Input } from "baseui/input";
import {
  HeadingSmall,
  ParagraphMedium,
  ParagraphSmall,
} from "baseui/typography";
import { FC, useEffect, useState } from "react";
import { RouteChildrenProps } from "react-router";
import useSWR from "swr";
import MobileSizeWrapper from "../components/wrapper/MobileSizeWrapper";
import { supabase } from "../externals/supabase";
import { getValidation } from "../lib/auth/getValidation";

const Login: React.FC<{ setType: any }> = ({ setType }) => {
  const { push } = useIonRouter();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [css] = useStyletron();
  const [showLoading, hideLoading] = useIonLoading();
  const [showToast] = useIonToast();
  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await showLoading("Checking your email & password...");
    try {
      const signin = await supabase.auth.signInWithPassword({
        email,
        password,
      });

      if (signin.error) throw signin.error;

      push("/home", "forward", "push");
      await showToast({
        message: "Successfully logged in!",
        duration: 2000,
        color: "success",
      });
    } catch (e: any) {
      await showToast({
        message: e.message,
        duration: 5000,
        color: "danger",
      });
    } finally {
      await hideLoading();
    }
  };

  return (
    <>
      <HeadingSmall>Login</HeadingSmall>
      <ParagraphMedium>Sign in with username and password</ParagraphMedium>

      <ul className={css({ paddingLeft: "0" })}>
        <form onSubmit={handleLogin}>
          <Block
            className={css({
              marginBottom: "12px",
            })}
          >
            <Input
              value={email}
              placeholder="Email"
              onChange={(e) => setEmail(e.target.value ?? "")}
              type="email"
            />
          </Block>
          <Block
            className={css({
              marginBottom: "19px",
            })}
          >
            <Input
              value={password}
              placeholder="password"
              onChange={(e) => setPassword(e.target.value ?? "")}
              type="password"
            />
          </Block>
          <div className="ion-text-center">
            <Button
              kind="primary"
              type="submit"
              className={css({ width: "100%" })}
            >
              Login
            </Button>
          </div>

          <Block
            className={css({
              marginTop: "20px",
              textAlign: "center",
            })}
          >
            <ParagraphSmall>
              Didn't have an account ?{" "}
              <Button kind="tertiary" onClick={() => setType(false)}>
                Create account
              </Button>
            </ParagraphSmall>
          </Block>
        </form>
      </ul>
    </>
  );
};

const SignUp: React.FC<{ setType: any }> = ({ setType }) => {
  const { push } = useIonRouter();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [css] = useStyletron();
  const [showLoading, hideLoading] = useIonLoading();
  const [showToast] = useIonToast();

  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await showLoading("Creating your account...");
    try {
      const signup = await supabase.auth.signUp({
        email,
        password,
        options: {
          data: {
            name,
          },
        },
      });
      if (signup.error) throw signup.error;

      push("/home", "forward", "push");
      await showToast({
        message: "Create account success",
        duration: 2000,
        color: "success",
      });
    } catch (e: any) {
      await showToast({
        message: e.message,
        duration: 5000,
        color: "danger",
      });
    } finally {
      await hideLoading();
    }
  };

  return (
    <>
      <HeadingSmall>Signup</HeadingSmall>
      <ParagraphMedium>Sign up with username and password</ParagraphMedium>

      <ul className={css({ paddingLeft: "0" })}>
        <form onSubmit={handleLogin}>
          <Block
            className={css({
              marginBottom: "12px",
            })}
          >
            <Input
              value={name}
              placeholder="Name"
              onChange={(e) => setName(e.target.value ?? "")}
              type="text"
            />
          </Block>
          <Block
            className={css({
              marginBottom: "12px",
            })}
          >
            <Input
              value={email}
              placeholder="Email"
              onChange={(e) => setEmail(e.target.value ?? "")}
              type="email"
            />
          </Block>
          <Block
            className={css({
              marginBottom: "19px",
            })}
          >
            <Input
              value={password}
              placeholder="password"
              onChange={(e) => setPassword(e.target.value ?? "")}
              type="password"
            />
          </Block>
          <div className="ion-text-center">
            <Button
              kind="primary"
              type="submit"
              className={css({ width: "100%" })}
            >
              Signup
            </Button>
          </div>

          <Block
            className={css({
              marginTop: "20px",
              textAlign: "center",
            })}
          >
            <ParagraphSmall>
              Have account ?{" "}
              <Button kind="tertiary" onClick={() => setType(true)}>
                Signin
              </Button>
            </ParagraphSmall>
          </Block>
        </form>
      </ul>
    </>
  );
};

export const AuthPage: FC<RouteChildrenProps> = () => {
  const { push } = useIonRouter();

  const [isSignIn, setIsSignIn] = useState(true);
  const { error, data } = useSWR("auth-status", getValidation);

  const isLoading = !error && !data;
  const isAuthenticated = data?.token && data.token.length > 13;

  useEffect(() => {
    if (!isLoading && isAuthenticated) push("/home", "forward", "replace");
  }, [isAuthenticated, isLoading, push]);

  return (
    <IonPage>
      <IonContent className="ion-padding">
        <MobileSizeWrapper>
          {isSignIn ? (
            <Login setType={setIsSignIn} />
          ) : (
            <SignUp setType={setIsSignIn} />
          )}
        </MobileSizeWrapper>
      </IonContent>
    </IonPage>
  );
};
