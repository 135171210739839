import { IonContent, IonPage } from "@ionic/react";
import { RouteChildrenProps } from "react-router";

import { Block } from "baseui/block";

import React from "react";
import CurrentLocation from "../components/CurrentLocation";
import AuthMiddleware from "../components/middleware/AuthMiddleware";
import PremiumMiddleware from "../components/middleware/PremiumMiddleware";
import useSelectedDevice from "../global-states/devices";
import "./Location.css";

const LocationPage: React.FC<RouteChildrenProps> = () => {
  const { device } = useSelectedDevice();

  return (
    <AuthMiddleware>
      <PremiumMiddleware>
        <IonPage>
          <IonContent>
            <Block height="100vh" width="100vw" backgroundColor="mono300">
              {device ? (
                <React.Suspense>
                  <CurrentLocation deviceId={device.device_id} />
                </React.Suspense>
              ) : null}
            </Block>
          </IonContent>
        </IonPage>
      </PremiumMiddleware>
    </AuthMiddleware>
  );
};

export default LocationPage;
