import { IonContent, IonPage } from "@ionic/react";
import React from "react";
import { RouteChildrenProps, withRouter } from "react-router";

import { Block } from "baseui/block";

import AuthMiddleware from "../components/middleware/AuthMiddleware";
import PremiumMiddleware from "../components/middleware/PremiumMiddleware";
import Track from "../components/Track";
import { useTrackHistoryDate } from "../global-states/modal";
import "./Location.css";
import useSelectedDevice from "../global-states/devices";

const LocationHistoryPage: React.FC<RouteChildrenProps> = () => {
  const { device } = useSelectedDevice();
  const { dates } = useTrackHistoryDate();

  return (
    <AuthMiddleware>
      <PremiumMiddleware>
        <IonPage id="main">
          <IonContent>
            <Block height="100vh" width="100vw" backgroundColor="mono300">
              {device ? (
                <React.Suspense>
                  <Track dates={dates as Date[]} deviceId={device.device_id} />
                </React.Suspense>
              ) : null}
            </Block>
          </IonContent>
        </IonPage>
      </PremiumMiddleware>
    </AuthMiddleware>
  );
};

export default withRouter(LocationHistoryPage as any);
