type APIConfig = {
  token: string;
};

type Latitude = number;
type Longitude = number;
type Coordinate = [Longitude, Latitude];
export type Coordinates = Coordinate[];

export class MapboxAPI {
  private baseUrl = "https://api.mapbox.com";
  private config: APIConfig;
  constructor(config: APIConfig) {
    this.config = config;
  }

  public async request(
    endpoint: string,
    init?: RequestInit
  ): Promise<Response> {
    const headers = new Headers(init?.headers);
    headers.append("Authorization", this.config.token);

    return fetch(
      this.baseUrl + endpoint + "&access_token=" + this.config.token,
      {
        ...init,
        headers,
      }
    ).then((r: any) => r.json());
  }

  public async matchRoutes(coordinates: Coordinates): Promise<any> {
    const stringCoordinates = coordinates
      .map((coordinate) => coordinate.join(","))
      .join(";");
    return await this.request(
      "/matching/v5/mapbox/driving/" +
        stringCoordinates +
        "?annotations=distance&overview=full&geometries=geojson&steps=true"
    );
  }

  public async getLocationDetail(...coordinate: Coordinate) {
    const stringCoordinates = coordinate.join(",");
    return await this.request(
      "/geocoding/v5/mapbox.places/" + stringCoordinates + ".json?"
    );
  }
}
