import { useIonRouter, useIonToast } from "@ionic/react";
import { FC, Suspense, useState } from "react";

import { Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import "yup-phone";

import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { Button } from "baseui/button";
import { ChevronLeft } from "baseui/icon";
import { Input, MaskedInput } from "baseui/input";
import { Select, TYPE, Value } from "baseui/select";
import {
  MonoParagraphMedium,
  ParagraphLarge,
  ParagraphMedium,
} from "baseui/typography";

import { GlobalAPI } from "../../lib/api";
import { withBackendUrl } from "../../utils/withBackendUrl";
import { concox } from "./concox";

const deviceSchema = Yup.object({
  title: Yup.string().required().min(4).max(20),
  imei: Yup.string()
    .matches(/^[0-9]{15}$/, {
      message: "Imei not valid",
      name: "Imei",
    })
    .required(),
  phone: Yup.string().phone().required(),
  deviceId: Yup.number().min(1).required(),
});

const AddDevices: FC = () => {
  const [css, theme] = useStyletron();
  const { goBack } = useIonRouter();
  const { push } = useIonRouter();
  const [toast] = useIonToast();

  const [deviceValue, setDeviceValue] = useState<Value>();
  const [errorMsg, setErrorMsg] = useState<string>();

  const onSubmit = (value: any, { setSubmitting }: FormikHelpers<any>) => {
    (async () => {
      try {
        const result = await GlobalAPI.request(
          withBackendUrl("/api/v1/devices"),
          {
            method: "POST",
            body: JSON.stringify({
              name: value.title,
              device_type_id: value.deviceId,
              ident: value.imei,
              phone:
                "+" +
                value.phone
                  .replaceAll(" ", "")
                  .replaceAll("(", "")
                  .replaceAll(")", "")
                  .replaceAll("-", "")
                  .replaceAll("+", ""),
            }),
          }
        );

        if (result.status === "success") {
          toast({
            message: "Device added successfuly",
            duration: 3000,
            color: "success",
          });
          push("/home");
          console.log({ result });
        }

        throw result.message || "Internal server error";
      } catch (error) {
        setErrorMsg(JSON.stringify(error));
      } finally {
        setSubmitting(false);
      }
    })();
  };

  return (
    <div
      className={css({ marginTop: "20px", maxWidth: "100%", width: "500px" })}
    >
      <Block
        className={css({
          display: "flex",
          gap: "10px",
          alignItems: "center",
          marginBottom: "20px",
        })}
      >
        <Button
          onClick={() => goBack()}
          size="compact"
          kind="secondary"
          className={css({
            padding: "2px !important",
            marginRight: "10px",
            display: "flex",
          })}
        >
          <ChevronLeft size="34px" />
        </Button>
        <ParagraphLarge
          className={css({ fontWeight: "bold !important" })}
          marginTop={0}
          marginBottom={0}
        >
          Add new device
        </ParagraphLarge>
      </Block>
      <Suspense fallback="Something went wrong">
        <Formik
          initialValues={{
            title: "",
            imei: "",
            phone: "",
            deviceId: 0,
          }}
          validationSchema={deviceSchema}
          onSubmit={onSubmit}
        >
          {({
            handleSubmit,
            values,
            setValues,
            touched,
            errors,
            handleBlur,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <Input
                name="title"
                onBlur={handleBlur}
                placeholder="Device Title"
                value={values.title}
                autoFocus
                autoComplete="false"
                onChange={(e) =>
                  setValues({ ...values, title: e.target.value })
                }
                error={Boolean(touched.title && errors.title)}
                positive={Boolean(touched.title && !errors.title)}
              />
              {touched.title && errors.title ? (
                <ParagraphMedium
                  marginBottom={0}
                  marginTop="3px"
                  className={css({ color: theme.colors.negative500 })}
                >
                  {errors.title}
                </ParagraphMedium>
              ) : null}
              <br />
              <Input
                name="imei"
                onBlur={handleBlur}
                placeholder="Device IMEI"
                value={values.imei}
                onChange={(e) => setValues({ ...values, imei: e.target.value })}
                error={Boolean(touched.imei && errors.imei)}
                positive={Boolean(touched.imei && !errors.imei)}
              />
              {touched.imei && errors.imei ? (
                <ParagraphMedium
                  marginBottom={0}
                  marginTop="3px"
                  className={css({ color: theme.colors.negative500 })}
                >
                  {errors.imei}
                </ParagraphMedium>
              ) : null}
              <br />
              <MaskedInput
                name="phone"
                onBlur={handleBlur}
                placeholder="Device phone number"
                mask="(+99) 999-9999-99999"
                value={values.phone}
                onChange={(e) =>
                  setValues({ ...values, phone: e.target.value })
                }
                error={Boolean(touched.phone && errors.phone)}
                positive={Boolean(touched.phone && !errors.phone)}
              />
              {touched.phone && errors.phone ? (
                <ParagraphMedium
                  marginBottom={0}
                  marginTop="3px"
                  className={css({ color: theme.colors.negative500 })}
                >
                  {errors.phone}
                </ParagraphMedium>
              ) : null}
              <br />
              <Select
                options={concox}
                labelKey="title"
                valueKey="id"
                placeholder="Choose device type"
                maxDropdownHeight="300px"
                type={TYPE.search}
                multi={false}
                value={deviceValue}
                overrides={{
                  Input: {
                    props: {
                      name: "deviceId",
                      onBlur: handleBlur,
                    },
                  },
                }}
                onChange={({ value }) => {
                  const deviceId = value[0].id;
                  if (!deviceId) return;
                  if (deviceId && !concox.map((d) => d.id).includes(+deviceId))
                    return;

                  setDeviceValue(value);
                  setValues({
                    ...values,
                    deviceId: +deviceId,
                  });
                }}
                error={Boolean(touched.deviceId && errors.deviceId)}
                positive={Boolean(touched.deviceId && !errors.deviceId)}
              />
              {touched.deviceId && errors.deviceId ? (
                <ParagraphMedium
                  marginBottom={0}
                  marginTop="3px"
                  className={css({ color: theme.colors.negative500 })}
                >
                  {errors.deviceId}
                </ParagraphMedium>
              ) : null}
              <br />
              <Button
                isLoading={isSubmitting}
                className={css({ width: "100%" })}
                type="submit"
              >
                Submit
              </Button>

              {Boolean(errorMsg) ? (
                <MonoParagraphMedium className={css({ textAlign: "center" })}>
                  {errorMsg}
                </MonoParagraphMedium>
              ) : (
                ""
              )}
            </form>
          )}
        </Formik>
      </Suspense>
    </div>
  );
};

export default AddDevices;
