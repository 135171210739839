import { IonContent, IonModal } from "@ionic/react";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { DatePicker } from "baseui/datepicker";
import { ParagraphLarge, ParagraphSmall } from "baseui/typography";
import { PropsWithChildren, useCallback } from "react";
import useSWR from "swr";
import { useIsModalOpen, useTrackHistoryDate } from "../global-states/modal";

const LocationContent: React.FC = () => {
  const [css] = useStyletron();
  return (
    <IonContent>
      <Block
        className={css({
          marginTop: "40px",
          marginLeft: "20px",
          marginRight: "20px",
        })}
      >
        <ParagraphLarge
          className={css({
            fontWeight: "bold",
          })}
        >
          Track real-time location
        </ParagraphLarge>
      </Block>
    </IonContent>
  );
};

const LocationHistoryContent: React.FC = () => {
  const [css] = useStyletron();
  const { data: routes } = useSWR("routes");

  const { setDates, dates } = useTrackHistoryDate();

  const gasEstimate = useCallback(() => {
    if (!routes?.info) return;

    return (
      <ParagraphSmall marginTop="0" marginBottom="20px">
        In these date range you've been drive for{" "}
        {routes?.info?.matchings[0].distance} Meter, estimated costs is Rp.{" "}
        {Math.round(
          (13900 / 52.9) * (routes?.info?.matchings[0].distance / 1000 || 0)
        )}
      </ParagraphSmall>
    );
  }, [routes?.info]);

  return (
    <IonContent>
      <Block
        className={css({
          marginTop: "40px",
          marginLeft: "20px",
          marginRight: "20px",
        })}
      >
        <ParagraphLarge
          marginBottom="5px"
          className={css({
            fontWeight: "bold",
          })}
        >
          Location history
        </ParagraphLarge>

        <div
          className={css({
            marginBottom: "14px",
          })}
        >
          <DatePicker
            value={dates}
            onChange={({ date }) => {
              if (Array.isArray(date)) setDates(date as Date[]);
            }}
            range
            required
            size="compact"
          />
        </div>

        {gasEstimate()}
      </Block>
    </IonContent>
  );
};

const MODEL_MAP = [
  { path: "/location", comp: <LocationContent /> },
  { path: "/location-history", comp: <LocationHistoryContent /> },
];

const Modal: React.FC<PropsWithChildren> = ({ children }) => {
  const { isOpen } = useIsModalOpen();

  return (
    <>
      {children}
      <IonModal
        isOpen={isOpen}
        initialBreakpoint={0.25}
        breakpoints={[0.25, 0.5, 0.75]}
        backdropDismiss={false}
        backdropBreakpoint={0.25}
        animated={false}
      >
        {MODEL_MAP.find((model) => model.path === window.location.pathname)
          ?.comp || null}
        modelasdasdasd
      </IonModal>
    </>
  );
};

export default Modal;
