import { IonContent, IonPage, useIonRouter } from "@ionic/react";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { Button } from "baseui/button";
import { HeadingLarge, ParagraphMedium } from "baseui/typography";
import { FC, useCallback, useEffect, useState } from "react";
import { RouteChildrenProps } from "react-router";
import AuthMiddleware from "../components/middleware/AuthMiddleware";
import NotPremiumMiddleware from "../components/middleware/NotPremiumMiddleware";
import { GlobalAPI } from "../lib/api";
import { withBackendUrl } from "../utils/withBackendUrl";

const Thanks: FC<RouteChildrenProps> = () => {
  const [css] = useStyletron();
  const { push } = useIonRouter();

  const [isLoading, setIsLoading] = useState(false);
  const [msg, setMsg] = useState("");

  const goToHome = useCallback(() => {
    push("/home", "forward", "replace");
  }, [push]);

  useEffect(() => {
    const paymentid = new URLSearchParams(window.location.search).get(
      "payment_intent"
    );

    if (paymentid)
      (async () => {
        try {
          setIsLoading(true);
          const result = await GlobalAPI.request(
            withBackendUrl("/api/v1/payment/update/" + paymentid),
            {
              method: "post",
            }
          );

          // explain that token is already in used, case "user attemps to reload the token to get longggg subs with same token"
          if (result.message === "already_used")
            push("/home", "forward", "replace");
          else push("/home", "forward", "replace");
        } catch (error) {
          setMsg("ERROR: Contact developers, copy paste this ID: " + paymentid);
        } finally {
          setIsLoading(false);
        }
      })();
  }, [push]);

  return (
    <AuthMiddleware>
      <NotPremiumMiddleware>
        <IonPage>
          <IonContent className="ion-padding">
            <Block
              className={css({
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                textAlign: "center",
              })}
            >
              <Block>
                <HeadingLarge marginBottom={0}>
                  Thanks for the meal!
                </HeadingLarge>
                <ParagraphMedium marginTop={0}>
                  Welcome and enjoy Keisha Protection!
                </ParagraphMedium>
                {msg ? <ParagraphMedium>{msg}</ParagraphMedium> : ""}
              </Block>
              <img
                className={css({ height: "300px", objectFit: "contain" })}
                alt="thanks"
                src="/assets/purr-sitting-cat-7.png"
              />
              <Button
                isLoading={isLoading}
                disabled={isLoading}
                onClick={goToHome}
                className={css({ marginTop: "auto" })}
              >
                Go to Home
              </Button>
            </Block>
          </IonContent>
        </IonPage>
      </NotPremiumMiddleware>
    </AuthMiddleware>
  );
};

export default Thanks;
