import { IonContent, IonMenuToggle } from "@ionic/react";

import { useStyletron } from "baseui";
import { Avatar } from "baseui/avatar";
import { Block } from "baseui/block";
import { Button } from "baseui/button";
import { FlexGridItem } from "baseui/flex-grid";
import { ParagraphLarge, ParagraphSmall } from "baseui/typography";
import { useContext } from "react";
import { useHistory } from "react-router";
import { supabase } from "../externals/supabase";
import useSelectedDevice from "../global-states/devices";
import { useDevices } from "../lib/device";
import { AuthContext } from "./middleware/AuthMiddleware";

const MenuContent: React.FC = () => {
  const [css, theme] = useStyletron();
  const { user } = useContext(AuthContext);
  const { push } = useHistory();
  const { data } = useDevices();

  const { setSelectedDevice } = useSelectedDevice();

  return (
    <IonContent className="ion-padding">
      <Block
        className={css({
          minHeight: "100%",
          display: "flex",
          flexDirection: "column",
        })}
      >
        <Block
          onClick={() => push("/profile")}
          className={css({
            display: "grid",
            gridTemplateColumns: "minmax(0,max-content) auto",
            alignItems: "center",
            gap: "10px",
          })}
        >
          <FlexGridItem>
            <Avatar name={user?.user_metadata?.name || "Avenger"} size="" />
          </FlexGridItem>
          <FlexGridItem>
            <ParagraphLarge>{user?.user_metadata?.name}</ParagraphLarge>
          </FlexGridItem>
        </Block>

        <Block
          className={css({
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          })}
        >
          <ParagraphLarge
            className={css({
              fontWeight: "bold !important",
              letterSpacing: "1px",
            })}
          >
            Devices ({data?.data?.length} / 10)
          </ParagraphLarge>
          <IonMenuToggle>
            <ParagraphSmall
              onClick={() => push("/devices/add")}
              className={css({ letterSpacing: "1px" })}
            >
              Add device
            </ParagraphSmall>
          </IonMenuToggle>
        </Block>
        <ul
          className={css({
            paddingLeft: "0",
            listStyle: "none",
            marginTop: 0,
            marginLeft: "-10px",
          })}
        >
          {data?.data?.map((d) => (
            <IonMenuToggle key={d.id}>
              <Button
                kind="tertiary"
                onClick={() => setSelectedDevice(d)}
                className={css({
                  width: "100%",
                  justifyContent: "flex-start",
                  borderBottom: "1px solid " + theme.colors.mono200,
                })}
              >
                {d.name}
              </Button>
            </IonMenuToggle>
          ))}
        </ul>

        <Button
          className={css({
            marginTop: "auto !important",
            justifySelf: "flex-end",
          })}
          onClick={async () => {
            await supabase.auth.signOut();
          }}
        >
          Logout
        </Button>
      </Block>
    </IonContent>
  );
};

export default MenuContent;
