import { useIonRouter } from "@ionic/react";
import { Session, User } from "@supabase/supabase-js";
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useEffect,
} from "react";
import useSWR from "swr";
import { supabase } from "../../externals/supabase";
import { useIsModalOpen } from "../../global-states/modal";
import { getValidation } from "../../lib/auth/getValidation";

const MODEL_MAP = ["/location", "/location-history"];

export type TAuthContext = {
  valid_until?: string;
  user?: User | null;
  token?: string | null;
};
export const AuthContext = createContext<TAuthContext>({
  valid_until: "",
  user: null,
  token: "",
});

const AuthMiddleware: React.FC<PropsWithChildren> = ({ children }) => {
  const { push, routeInfo } = useIonRouter();
  const { setIsOpen } = useIsModalOpen();

  const {
    mutate: mutateSession,
    data,
    error,
  } = useSWR("auth-status", null, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  const isLoading = !error && !data;
  const isAuthenticated = data?.token && data.token.length > 13;

  const handleAuthChange = useCallback(
    (session?: Session | null) => {
      if (!session || (!data?.token && session?.access_token !== data?.token)) {
        mutateSession(getValidation());
      }
    },
    [data, mutateSession]
  );

  useEffect(() => {
    setIsOpen(MODEL_MAP.includes(routeInfo.pathname));
  }, [routeInfo, setIsOpen]);

  useEffect(() => {
    handleAuthChange();
    supabase.auth.onAuthStateChange(async (_event, session) => {
      handleAuthChange(session);
    });
  }, [handleAuthChange]);

  useEffect(() => {
    if (!isLoading && !isAuthenticated) push("/", "forward", "replace");
  }, [isAuthenticated, isLoading, push]);

  if (isAuthenticated)
    return (
      <AuthContext.Provider
        value={{
          valid_until: data?.validUntil,
          user: data.user,
          token: data.token,
        }}
      >
        {children}
      </AuthContext.Provider>
    );
  else return <>Loading</>;
};

export default AuthMiddleware;
