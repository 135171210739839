import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { FC, PropsWithChildren } from "react";

const MobileSizeWrapper: FC<
  PropsWithChildren
> = ({ children }) => {
  const [css] = useStyletron();

  return (
    <Block
      className={css({
        maxWidth: "500px",
        margin: "0 auto",
      })}
    >
      {children}
    </Block>
  );
};
export default MobileSizeWrapper;
