import { useContext, useEffect, useState } from "react";
import useSWR from "swr";

import { useIonToast } from "@ionic/react";
import { Action } from "../lib/api";
import { AuthContext } from "../components/middleware/AuthMiddleware";

const useEngine = (deviceId: number) => {
  const [showToast] = useIonToast();
  const { token } = useContext(AuthContext);

  const action = new Action({
    deviceId,
    token: token || "",
  });

  const {
    data: relay,
    isValidating,
    error,
    mutate,
  } = useSWR(
    "engine.blocked.status",
    () =>
      action
        .getTelemetry("engine.blocked.status")
        .then((r) => r?.result[0].telemetry["engine.blocked.status"].value),
    {
      revalidateOnFocus: false,
    }
  );

  const isLoading = isValidating || (!relay && !error);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isCut, setIsCut] = useState(relay);
  const IS_CUT_ON_OFF = isCut ? "ON" : "OFF";
  const IS_CUT_ON_OFF_REVERSE = !isCut ? "ON" : "OFF";

  // const handleChangeEngineStatusViaSMS = async (status: boolean) => {
  //   try {
  //     const [isSent, response] = await action.relayControlSMS(status);

  //     if (!isSent || response.errors.length) throw response.errors;
  //     toaster.positive("sms sent");
  //     setEngineStatus(status);
  //   } catch (error) {
  //     toaster.negative("send via SMS failed: ");

  //     console.log(error);
  //   }
  // };

  const handleChangeEngineStatus = async (status: boolean) => {
    setIsSubmitting(true);
    try {
      const setRelayResponse = await action.relayControl(status);
      if (!setRelayResponse?.result[0].executed) throw setRelayResponse;
      showToast("Command sent " + setRelayResponse?.result[0].response, 3000);
      setIsCut(status);
    } catch (error) {
      showToast("Command is added to Queue", 3000);
    } finally {
      setIsSubmitting(false);
      mutate();
    }
  };

  useEffect(() => {
    setIsCut(relay);
  }, [relay]);

  return {
    isLoading,
    isSubmitting,
    handleChangeEngineStatus,
    isCut,
    IS_CUT_ON_OFF_REVERSE,
    IS_CUT_ON_OFF,
  };
};

export default useEngine;
