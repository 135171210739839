import { IonContent, IonPage } from "@ionic/react";
import { RouteChildrenProps, withRouter } from "react-router";

import HomePageContent from "../components/HomePageContent";
import AuthMiddleware from "../components/middleware/AuthMiddleware";
import PremiumMiddleware from "../components/middleware/PremiumMiddleware";
import NotificationProvider from "../components/provider/NotificationProvider";
import MenuWrapper from "../components/wrapper/MenuWrapper";
import MobileSizeWrapper from "../components/wrapper/MobileSizeWrapper";

const Home: React.FC<RouteChildrenProps> = () => {
  return (
    <>
      <AuthMiddleware>
        <PremiumMiddleware>
          <MenuWrapper>
            <IonPage id="main">
              <IonContent className="ion-padding">
                <NotificationProvider>
                  <MobileSizeWrapper>
                    <HomePageContent />
                  </MobileSizeWrapper>
                </NotificationProvider>
              </IonContent>
            </IonPage>
          </MenuWrapper>
        </PremiumMiddleware>
      </AuthMiddleware>
    </>
  );
};

export default withRouter(Home as any);
