import { IonIcon, useIonRouter } from "@ionic/react";
import { useStyletron } from "baseui";
import { Block, BlockProps } from "baseui/block";
import { Button } from "baseui/button";
import { Checkbox, STYLE_TYPE } from "baseui/checkbox";
import { FlexGrid, FlexGridItem } from "baseui/flex-grid";
import { Heading, HeadingLevel } from "baseui/heading";
import {
  HeadingXSmall,
  ParagraphLarge,
  ParagraphSmall,
} from "baseui/typography";
import { chevronForwardOutline } from "ionicons/icons";
import useSelectedDevice from "../global-states/devices";
import ActionItemEngine from "./ActionItem";
import DevicesNotConnect from "./DeviceNotConnect";

const pageGridItemsProp: BlockProps = {
  backgroundColor: "mono200",
  minHeight: "150px",
  padding: "15px 15px",
};

const quickActionItemsProp: BlockProps = {
  backgroundColor: "mono200",
  padding: "8px 10px",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around",
};

const HomeWithSelectedDevice: React.FC<{ deviceId: number }> = ({
  deviceId,
}) => {
  const [css] = useStyletron();
  const { push } = useIonRouter();

  const { device } = useSelectedDevice();

  return (
    <>
      <HeadingLevel>
        <Heading styleLevel={2} marginBottom="0">
          {device?.name}
        </Heading>
        <ParagraphSmall marginTop="0">
          Your {device?.name} Dashboard
        </ParagraphSmall>

        {device?.device_id ? (
          <>
            <FlexGrid
              flexGridColumnCount={2}
              flexGridColumnGap="10px"
              flexGridRowGap="scale800"
              marginTop="60px"
            >
              <FlexGridItem>
                <Block
                  {...pageGridItemsProp}
                  className={css({
                    borderRadius: "20px",
                    height: "100%",
                  })}
                >
                  <img
                    src="/assets/purr-sitting-cat-4.png"
                    className={css({
                      height: "90px",
                    })}
                    alt="Kiyowo"
                  />
                  <ParagraphLarge
                    className={css({
                      fontWeight: "bold",
                    })}
                  >
                    Track real-time location
                  </ParagraphLarge>
                  <Button
                    className={css({
                      marginTop: "10px",
                      marginBottom: "7px",
                    })}
                    kind="primary"
                    shape="round"
                    size="compact"
                    onClick={() => {
                      push("/location?modalOpened=true");
                    }}
                  >
                    <IonIcon
                      icon={chevronForwardOutline}
                      className={css({
                        width: "20px",
                        height: "20px",
                      })}
                    />
                  </Button>
                </Block>
              </FlexGridItem>
              <FlexGridItem>
                <Block
                  {...pageGridItemsProp}
                  className={css({
                    borderRadius: "20px",
                    height: "100%",
                  })}
                >
                  <img
                    src="/assets/purr-6.png"
                    className={css({
                      height: "90px",
                    })}
                    alt="Kiyowo"
                  />
                  <ParagraphLarge
                    className={css({
                      fontWeight: "bold",
                    })}
                  >
                    View location & <br /> history
                  </ParagraphLarge>
                  <Button
                    className={css({
                      marginTop: "10px",
                      marginBottom: "7px",
                    })}
                    kind="primary"
                    shape="round"
                    size="compact"
                    onClick={() => {
                      push("/location-history");
                    }}
                  >
                    <IonIcon
                      icon={chevronForwardOutline}
                      className={css({
                        width: "20px",
                        height: "20px",
                      })}
                    />
                  </Button>
                </Block>
              </FlexGridItem>
            </FlexGrid>

            <HeadingXSmall
              marginTop="40px"
              className={css({
                fontWeight: "bold",
              })}
            >
              Quick actions
            </HeadingXSmall>
            <FlexGrid
              flexGridColumnCount={3.5}
              flexGridColumnGap="scale300"
              flexGridRowGap="scale800"
            >
              <FlexGridItem>
                <ActionItemEngine />
              </FlexGridItem>
              <FlexGridItem>
                <Block
                  {...quickActionItemsProp}
                  className={css({
                    borderRadius: "8px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  })}
                >
                  <ParagraphSmall
                    marginTop="12px"
                    marginBottom="10px"
                    className={css({
                      lineHeight: "1.3 !important",
                      fontWeight: "bold !important",
                    })}
                  >
                    Vibration Alarm
                  </ParagraphSmall>
                  <Checkbox
                    checked={false}
                    checkmarkType={STYLE_TYPE.toggle_round}
                  ></Checkbox>
                </Block>
              </FlexGridItem>
              <FlexGridItem>
                <Block
                  {...quickActionItemsProp}
                  className={css({
                    borderRadius: "8px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  })}
                >
                  <ParagraphSmall
                    marginTop="12px"
                    marginBottom="10px"
                    className={css({
                      lineHeight: "1.3 !important",
                      fontWeight: "bold !important",
                    })}
                  >
                    Overspeed Alarm
                  </ParagraphSmall>
                  <Checkbox
                    checked={false}
                    checkmarkType={STYLE_TYPE.toggle_round}
                  ></Checkbox>
                </Block>
              </FlexGridItem>
            </FlexGrid>
          </>
        ) : (
          <DevicesNotConnect />
        )}
      </HeadingLevel>
    </>
  );
};

export default HomeWithSelectedDevice;
