import { IonContent, IonPage } from "@ionic/react";
import { FC } from "react";
import { RouteChildrenProps } from "react-router";
import AddDevices from "../../components/devices/Add";
import AuthMiddleware from "../../components/middleware/AuthMiddleware";
import PremiumMiddleware from "../../components/middleware/PremiumMiddleware";
import MobileSizeWrapper from "../../components/wrapper/MobileSizeWrapper";

const AddDevicesPage: FC<RouteChildrenProps> = () => {
  return (
    <>
      <AuthMiddleware>
        <PremiumMiddleware>
          <IonPage id="main">
            <IonContent className="ion-padding">
              <MobileSizeWrapper>
                <AddDevices />
              </MobileSizeWrapper>
            </IonContent>
          </IonPage>
        </PremiumMiddleware>
      </AuthMiddleware>
    </>
  );
};

export default AddDevicesPage;
