export const concox = [
  { id: 510, title: "Concox AT1" },
  { id: 511, title: "Concox AT2" },
  { id: 193, title: "Concox AT3" },
  { id: 194, title: "Concox AT4" },
  { id: 277, title: "Concox AT4U" },
  { id: 640, title: "Concox AT5" },
  { id: 350, title: "Concox AT6" },
  { id: 209, title: "Concox BL10" },
  { id: 844, title: "Concox BL10S" },
  { id: 858, title: "Concox BL11" },
  { id: 399, title: "Concox CT10" },
  { id: 513, title: "Concox EG02" },
  { id: 211, title: "Concox ET25" },
  { id: 189, title: "Concox GK309E" },
  { id: 191, title: "Concox GK310" },
  { id: 195, title: "Concox GT03A" },
  { id: 278, title: "Concox GT03C" },
  { id: 798, title: "Concox GT06D" },
  { id: 197, title: "Concox GT06E" },
  { id: 198, title: "Concox GT06F" },
  { id: 196, title: "Concox GT06N" },
  { id: 201, title: "Concox GT08" },
  { id: 199, title: "Concox GT100" },
  { id: 95, title: "Concox GT230" },
  { id: 188, title: "Concox GT300" },
  { id: 96, title: "Concox GT710" },
  { id: 200, title: "Concox GT800" },
  { id: 203, title: "Concox GV20" },
  { id: 512, title: "Concox GV40" },
  { id: 266, title: "Concox HVT001" },
  { id: 627, title: "Concox JC200" },
  { id: 732, title: "Concox JM-LL01" },
  { id: 1288, title: "Concox JM-LL303" },
  { id: 725, title: "Concox JM-VG01U" },
  { id: 726, title: "Concox JM-VG02U" },
  { id: 1095, title: "Concox JM-VL01" },
  { id: 1109, title: "Concox JM-VL03" },
  { id: 1096, title: "Concox JM-VL04" },
  { id: 466, title: "Concox JS40" },
  { id: 204, title: "Concox JV200" },
  { id: 202, title: "Concox MOPLUS" },
  { id: 210, title: "Concox OB22" },
  { id: 192, title: "Concox Q2" },
  { id: 190, title: "Concox Qbit" },
  { id: 656, title: "Concox WeTrack 140" },
  { id: 205, title: "Concox WeTrack2" },
  { id: 206, title: "Concox WeTrack Lite" },
  { id: 207, title: "Concox X1" },
  { id: 208, title: "Concox X3" },
];
