import create from "zustand";
import { combine } from "zustand/middleware";

export type Device = {
  id: string;
  user_id: string;
  name: string;
  device_type_id: number;
  ident: string;
  phone_number: string;
  device_id: number;
};

type DeviceState = { device: Device | null; isConnected: boolean };

const getInitialDevice = () => {
  if (localStorage && Boolean(localStorage.getItem("default-device")))
    return JSON.parse(localStorage.getItem("default-device") || "");
  return null;
};

const initialSelectedDevice: DeviceState = {
  device: getInitialDevice(),
  isConnected: false,
};

const useSelectedDevice = create(
  combine(initialSelectedDevice, (set) => ({
    setSelectedDevice: (device: Device | null) => {
      set({ device });
      localStorage.setItem("default-device", JSON.stringify(device));
    },
    setIsConnected: (isConnected: boolean) => {
      set({ isConnected });
    },
  }))
);

export default useSelectedDevice;
