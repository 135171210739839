import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import React, { useEffect, useState } from "react";

import { useStyletron } from "baseui";
import { Button } from "baseui/button";
import { Spinner } from "baseui/icon";
import { ParagraphMedium } from "baseui/typography";

import { withFrontendUrl } from "../utils/withBackendUrl";

export default function CheckoutForm() {
  const [css] = useStyletron();

  const stripe = useStripe();
  const elements = useElements();

  const [isSubmited, setIsSubmited] = useState(false);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!stripe || !isSubmited || !clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret);
  }, [stripe, isSubmited]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsSubmited(true);
    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: withFrontendUrl("/thanks"),
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error?.message || "");
    } else {
      setMessage("An unexpected error occurred.");
    }

    setIsLoading(false);
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" />
      <Button
        kind="primary"
        className={css({
          width: "100%",
          marginTop: "30px",
        })}
        isLoading={isLoading || !stripe || !elements}
        id="submit"
      >
        {isLoading || !stripe || !elements ? <Spinner /> : "Pay"}
      </Button>
      {/* Show any error or success messages */}
      {message && (
        <ParagraphMedium
          className={css({ textAlign: "center" })}
          id="payment-message"
        >
          {message}
        </ParagraphMedium>
      )}
    </form>
  );
}
