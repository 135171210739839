import { useIonRouter } from "@ionic/react";
import { FC, PropsWithChildren, useEffect } from "react";
import usePremium from "../../hooks/usePremium";

const PremiumMiddleware: FC<PropsWithChildren> = ({ children }) => {
  const { push } = useIonRouter();
  const { isPremiumPlan } = usePremium();

  useEffect(() => {
    if (!isPremiumPlan) push("/plan", "forward", "replace");
  }, [isPremiumPlan, push]);

  if (isPremiumPlan) return <>{children}</>;
  return <></>;
};

export default PremiumMiddleware;
