import { IonContent, IonGrid, IonPage, IonRow } from "@ionic/react";
import { useStyletron } from "styletron-react";
import { RouteChildrenProps } from "react-router";
import useSWR from "swr";

import { AppNavBar } from "baseui/app-nav-bar";
import { StyledBody, StyledCell, StyledRow, StyledTable } from "baseui/table";

import { Devices } from "../lib/api";
import { useContext } from "react";
import AuthMiddleware, {
  AuthContext,
} from "../components/middleware/AuthMiddleware";
import PremiumMiddleware from "../components/middleware/PremiumMiddleware";

const TelemetryPage: React.FC<RouteChildrenProps> = () => {
  const [css] = useStyletron();
  const { token } = useContext(AuthContext);

  const devices = new Devices({
    deviceId: 4653309,
    token: token || "",
  });

  const { data } = useSWR("telemetry", () => devices.getTelemetry());
  const telemetry = data?.result[0].telemetry;

  const tablesData = Object.keys(telemetry || []).map((key) => {
    const { value } = telemetry![key];

    if (typeof value === "object") {
      return Object.keys(telemetry![key]).map((nestedKey) => [
        `${key} | ${nestedKey}`,
        JSON.stringify(value[nestedKey]),
      ]);
    }

    return [key, JSON.stringify(value)];
  });

  return (
    <AuthMiddleware>
      <PremiumMiddleware>
        <IonPage>
          <AppNavBar title="Telemetry" />
          <IonContent>
            <IonGrid style={{}}>
              <IonRow></IonRow>
              <IonRow>
                <StyledTable className={css({ width: "100%" })}>
                  <StyledBody>
                    {tablesData.map((d) => (
                      <StyledRow key={JSON.stringify(d[0])}>
                        <StyledCell>{d[0]}</StyledCell>
                        <StyledCell>{d[1]}</StyledCell>
                      </StyledRow>
                    ))}
                  </StyledBody>
                </StyledTable>
              </IonRow>
            </IonGrid>
          </IonContent>
        </IonPage>
      </PremiumMiddleware>
    </AuthMiddleware>
  );
};

export default TelemetryPage;
