import { useIonRouter } from "@ionic/react";
import { useStyletron } from "baseui";
import { Button, KIND, SIZE } from "baseui/button";
import Hide from "baseui/icon/hide";

export default function HomeEmptyState() {
  const [css, theme] = useStyletron();
  const { push } = useIonRouter();

  return (
    <div
      className={css({
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        marginTop: "100px",
      })}
    >
      <Hide size={theme.sizing.scale1600} />
      <div
        className={css({
          ...theme.typography.HeadingXSmall,
          paddingBlockStart: theme.sizing.scale650,
          paddingBlockEnd: theme.sizing.scale500,
        })}
      >
        No device selected
      </div>
      <div
        className={css({
          ...theme.typography.ParagraphMedium,
          paddingBlockEnd: theme.sizing.scale650,
        })}
      >
        Select device on the menu or create one!
      </div>
      <Button
        onClick={() => {push("/devices/add")}}
        kind={KIND.secondary}
        size={SIZE.compact}
      >
        Add device
      </Button>
    </div>
  );
}
