import * as React from "react";
import Map, { Marker, ViewportProps } from "react-map-gl";
import useSWR from "swr";
import { useIsMounted, useWindowSize } from "usehooks-ts";

import { LocationPuck } from "baseui/map-marker";
import { Skeleton } from "baseui/skeleton";

import { Devices } from "../lib/api";
import { AuthContext } from "./middleware/AuthMiddleware";

const userLocation = {
  latitude: 37.768495131168336,
  longitude: -122.38856031220648,
};
const initialViewport: Partial<ViewportProps> = {
  ...userLocation,
  zoom: 14,
};

const useCurrenLocation = (deviceId: number) => {
  const isMounted = useIsMounted();
  const { token } = React.useContext(AuthContext);

  const [viewport, setViewport] =
    React.useState<Partial<ViewportProps>>(initialViewport);

  const devices = new Devices({
    deviceId,
    token: token || "",
  });

  const { data: telemetry } = useSWR("telemetry", () => devices.getTelemetry());

  const latitude = telemetry?.result[0].telemetry["position.latitude"].value;
  const longitude = telemetry?.result[0].telemetry["position.longitude"].value;

  React.useEffect(() => {
    if (latitude && longitude && isMounted()) {
      setViewport((viewport) => ({
        ...viewport,
        latitude,
        longitude,
      }));
    }
  }, [latitude, longitude, isMounted]);

  return { viewport, setViewport, latitude, longitude };
};

const CurrentLocation: React.FC<{ deviceId: number }> = ({ deviceId }) => {
  const { latitude, longitude, setViewport, viewport } = useCurrenLocation(deviceId);
  const { width, height } = useWindowSize();

  if (!latitude || !longitude)
    return (
      <>
        <Skeleton width={`${width - 32}px`} height={`${height * 0.9}px`} />
      </>
    );
  return (
    <div>
      <React.Suspense fallback="loading...">
        <Map
          {...viewport}
          mapboxApiAccessToken="pk.eyJ1IjoiaW5kb3NvZnQiLCJhIjoiY2w4dHR4MmV6MDk4bTNuazU3ZjBtZXY2ayJ9.bZ7S6j4MdMpndwpPIr8cQA"
          onViewportChange={(_viewport) =>
            setViewport({
              ...viewport,
              ..._viewport,
            } as Partial<ViewportProps>)
          }
          height={height}
          width={width}
          mapStyle="mapbox://styles/mapbox/streets-v9"
        >
          <Marker longitude={longitude} latitude={latitude}>
            <LocationPuck showHeading={false} confidenceRadius={120} />
          </Marker>
        </Map>
      </React.Suspense>
    </div>
  );
};

export default CurrentLocation;
