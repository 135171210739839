import { useContext, useEffect } from "react";
import useSWR from "swr";
import { AuthContext } from "../components/middleware/AuthMiddleware";
import { Devices } from "../lib/api";
import { Coordinates, MapboxAPI } from "../lib/mapbox";

const mapbox = new MapboxAPI({
  token:
    "pk.eyJ1IjoiaW5kb3NvZnQiLCJhIjoiY2w4dHR4MmV6MDk4bTNuazU3ZjBtZXY2ayJ9.bZ7S6j4MdMpndwpPIr8cQA",
});

export type Routes = {
  info?: any;
  coordinates: any;
};

const useRoutes = (dates: Date[]) => {
  const { token } = useContext(AuthContext);

  const devices = new Devices({
    deviceId: 4653309,
    token: token || "",
  });

  const { mutate: routeMutate, ...swrRoutes } = useSWR(
    "routes",
    async (): Promise<Routes> => {
      const messages = await devices.getMessages({
        from: dates[0],
        to: dates[1],
      });

      const coordinates = messages?.result.map((d: any) => [
        d["position.longitude"],
        d["position.latitude"],
      ]);

      if ((coordinates as [])?.length >= 101) {
        return { coordinates };
      }

      try {
        const matches = (await mapbox.matchRoutes(
          coordinates as Coordinates
        )) as any;

        if (!matches) throw new Error();

        if (matches.code === "Ok") {
          const coordinates = matches.tracepoints
            ?.filter((point: any) => point !== null)
            .map((point: any) => point.location);

          return { coordinates, info: matches };
        } else throw new Error();
      } catch {
        return { coordinates };
      }
    },
    { revalidateOnFocus: false }
  );

  useEffect(() => {
    if (dates) {
      routeMutate();
    }
  }, [dates, routeMutate]);

  return {
    ...swrRoutes,
  };
};

export default useRoutes;
