import { IonIcon, IonMenuToggle } from "@ionic/react";
import { menuSharp } from "ionicons/icons";
import { FC, useEffect } from "react";

import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import useSWR from "swr";
import useSelectedDevice from "../global-states/devices";
import HomeEmptyState from "./HomeEmptyState";
import HomeWithSelectedDevice from "./HomeWithSelectedDevice";

const HomePageContent: FC = () => {
  const [css] = useStyletron();
  const { device, setSelectedDevice } = useSelectedDevice();
  const { data, error } = useSWR("devices");

  useEffect(() => {
    if (!device?.id && data?.data && data?.data[0]) {
      setSelectedDevice(data?.data[0]);
    }

    if (!error && !data?.data.length) setSelectedDevice(null);
  }, [device, data, setSelectedDevice, error]);

  return (
    <Block className={css({ height: "100%" })}>
      <IonMenuToggle>
        <IonIcon
          className={css({
            width: "30px",
            height: "30px",
          })}
          icon={menuSharp}
        />
      </IonMenuToggle>
      {device ? (
        <HomeWithSelectedDevice deviceId={device.device_id} />
      ) : (
        <HomeEmptyState />
      )}
    </Block>
  );
};

export default HomePageContent;
