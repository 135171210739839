import { IonRouterOutlet } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Route } from "react-router";
import { AuthPage } from "./pages/Auth";
import AddDevicesPage from "./pages/devices/Add";
import Home from "./pages/Home";
import LocationPage from "./pages/Location";
import LocationHistoryPage from "./pages/LocationHistory";
import Payment from "./pages/Payment";
import PlanPage from "./pages/Plan";
import ProfilePage from "./pages/Profile";
import TelemetryPage from "./pages/Telemetry";
import Thanks from "./pages/Thanks";

const Routes = () => {
  return (
    <IonReactRouter>
      <IonRouterOutlet>
        <Route exact path="/home" render={(props) => <Home {...props} />} />
        <Route
          exact
          path="/profile"
          render={(props) => <ProfilePage {...props} />}
        />

        <Route
          exact
          path="/devices/add"
          render={(props) => <AddDevicesPage {...props} />}
        />

        <Route
          exact
          path="/location"
          render={(props) => <LocationPage {...props} />}
        />
        <Route
          exact
          path="/location-history"
          render={(props) => <LocationHistoryPage {...props} />}
        />

        <Route
          path="/telemetry"
          render={(props) => <TelemetryPage {...props} />}
        />

        <Route exact path="/plan" render={(props) => <PlanPage {...props} />} />

        <Route
          exact
          path="/payment"
          render={(props) => <Payment {...props} />}
        />

        <Route exact path="/thanks" render={(props) => <Thanks {...props} />} />

        <Route exact path="/" render={(props) => <AuthPage {...props} />} />
      </IonRouterOutlet>
    </IonReactRouter>
  );
};

export default Routes;
