import * as React from "react";
import Map, { Layer, Source, ViewportProps } from "react-map-gl";
import useSWR from "swr";
import { useWindowSize } from "usehooks-ts";
import { Devices } from "../lib/api";

import { useStyletron } from "baseui";
import { Skeleton } from "baseui/skeleton";
import { Spinner } from "baseui/spinner";
import useRoutes from "../hooks/useRoutes";
import { AuthContext } from "./middleware/AuthMiddleware";

const userLocation = {
  latitude: 37.768495131168336,
  longitude: -122.38856031220648,
};
const initialViewport: Partial<ViewportProps> = {
  ...userLocation,
  zoom: 14,
};

const useTrackLocation = (selected: number, dates: Date[]) => {
  const { token } = React.useContext(AuthContext);

  const [viewport, setViewport] =
    React.useState<Partial<ViewportProps>>(initialViewport);

  const devices = new Devices({
    deviceId: 4653309,
    token: token || "",
  });

  const { data: telemetry } = useSWR("telemetry", () => devices.getTelemetry());

  const latitude = telemetry?.result[0].telemetry["position.latitude"].value;
  const longitude = telemetry?.result[0].telemetry["position.longitude"].value;

  React.useEffect(() => {
    if (latitude && longitude) {
      setViewport((viewport) => ({
        ...viewport,
        latitude,
        longitude,
      }));
    }
  }, [latitude, longitude]);

  return {
    viewport,
    setViewport,
    latitude,
    longitude,
  };
};

const Track: React.FC<{ dates: Date[]; deviceId: number }> = ({
  dates,
  deviceId,
}) => {
  const { data: coordinates, isValidating } = useRoutes(dates);
  const { viewport, setViewport, latitude, longitude } = useTrackLocation(
    deviceId,
    dates
  );

  const [css] = useStyletron();
  const { width, height } = useWindowSize();

  if (!latitude || !longitude || !coordinates)
    return <Skeleton width={`${width - 32}px`} height={`${height * 0.9}px`} />;

  return (
    <>
      {isValidating ? (
        <div
          className={css({
            position: "fixed",
            inset: 0,
            zIndex: 1,
            backgroundColor: "rgba(0,0,0,0.4)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          })}
        >
          <Spinner />
        </div>
      ) : (
        ""
      )}
      <React.Suspense fallback="error">
        <Map
          {...viewport}
          mapboxApiAccessToken="pk.eyJ1IjoiaW5kb3NvZnQiLCJhIjoiY2w4dHR4MmV6MDk4bTNuazU3ZjBtZXY2ayJ9.bZ7S6j4MdMpndwpPIr8cQA"
          mapStyle="mapbox://styles/mapbox/streets-v9"
          onViewportChange={(_viewport) =>
            setViewport({
              ...viewport,
              ..._viewport,
            })
          }
          width={width}
          height={height}
        >
          <Source
            id="polylineLayer"
            type="geojson"
            lineMetrics={true}
            data={{
              type: "Feature",
              properties: {},
              geometry: {
                type: "LineString",
                coordinates: coordinates.coordinates as number[][],
              },
            }}
          >
            <Layer
              id="lineLayer"
              type="line"
              source="my-data"
              layout={{
                "line-join": "round",
                "line-cap": "round",
              }}
              paint={{
                "line-color": "red",
                "line-width": 6,
                "line-opacity-transition": {
                  delay: 0.2,
                  duration: 0.3,
                },
                "line-gradient": [
                  "interpolate",
                  ["linear"],
                  ["line-progress"],
                  0,
                  "rgba(0,0,0,0.2)",
                  1,
                  "#222",
                ],
              }}
            />
          </Source>
          {/* <Layer {...(parkLayer as any)} /> */}
        </Map>
      </React.Suspense>
    </>
  );
};

export default Track;
