import { useIonRouter, useIonToast } from "@ionic/react";
import { PropsWithChildren, useEffect } from "react";
import usePremium from "../../hooks/usePremium";

const NotPremiumMiddleware: React.FC<PropsWithChildren> = ({ children }) => {
  const { push } = useIonRouter();
  const { isPremiumPlan } = usePremium();
  const [show] = useIonToast();

  useEffect(() => {
    if (isPremiumPlan) {
      show("You already have Premium plan", 3000);
      push("/home", "forward", "replace");
    }
  }, [isPremiumPlan, show, push]);

  if (!isPremiumPlan) return <>{children}</>;

  return <></>;
};

export default NotPremiumMiddleware;
